/**************************************************************************
 * GETTERS
 *************************************************************************/

export const getters = {
    /**
     * @function get meter data by meteringCode
     * @param {Object} state => {String} meteringCode
     * @returns {function(*): *} => {Object}
     */
    getMeterData(state) {
        return (meteringCode) => {
            if (!state.meters) return {};
            return state.meters.filter(
                (meter) => meter.meteringCode === meteringCode
            )[0];
        };
    },
    /**
     * @function get meter register data by meterRegisterId
     * @param {Object} state => {String} meterRegisterId
     * @returns {function(*): *} => {Object}
     */
    getMeterRegisterData(state) {
        return (meterRegisterId) =>
            state.meters.find(
                (meter) => meter.meterRegisterId === meterRegisterId
            );
    },
    /**
     * @function get mediaType data from mediaType config by mediaType number
     * @param state => {Number} number
     * @returns {function(*): *} => {Object}
     */
    getMediaTypeData(state) {
        return (number) => {
            if (!state.config.mediaTypes) return {};
            return state.config.mediaTypes.find(
                (mediaType) => mediaType.no === number
            );
        };
    },
    /**
     * @function get customer data by customerId
     * @param {Object} state => {String} customerId
     * @returns {function(*): *} => {Object}
     */
    getCustomerData(state) {
        return (customerId) =>
            state.customers.find(
                (customer) => customer.customerId === customerId
            );
    },
    /**
     * @function get all customers for a meter
     * @param {Object} state => {String} customerId
     * @returns {function(*): *} => {array}
     */
    getCustomersForMeter(state) {
        return (meteringCode) => {
            const customerIds = state.meters
                .filter((meter) => meter.meteringCode === meteringCode)
                .map((meter) => meter.customerId)
                .filter((value, index, self) => self.indexOf(value) === index);
            return state.customers.filter((customer) =>
                customerIds.includes(customer.customerId)
            );
        };
    },
    /**
     * @function get meter registers
     * @param {Object} state => {String} meteringCode
     * @returns {function(*): *} => {array}
     */
    getMeterRegisters(state) {
        return (meteringCode) => {
            return state.meters.filter(
                (meter) => meter.meteringCode === meteringCode
            );
        };
    },
    /**
     * @function get meter registers
     * @param {Object} state => {String} meterRegisterId
     * @returns {function(*): *} => {array}
     */
    getMeterRegister(state) {
        return (meterRegisterId) => {
            return state.meters.find(
                (meter) => meter.meterRegisterId === meterRegisterId
            );
        };
    },
    /**
     * @function get reading register data (Zählwerk/Zähler)
     * @param {Object} state => {String} meteringCode
     * @returns {function(*): T[]} => {Object}
     */
    getReadingRegisters(state) {
        return (meteringCode) =>
            state.readings
                .filter((meter) => meter.meteringCode === meteringCode)
                .sort((a, b) => a.meterRegisterNumber - b.meterRegisterNumber);
    },
    /**
     * @function get address of meter
     * @param {Object} state => {String} meteringCode
     * @returns {function(*): T[]} => {Object}
     */
    getMeterAdress(state) {
        return (meteringCode) => {
            if (!state.meters) return "";
            const meter = state.meters.filter(
                (meter) => meter.meteringCode === meteringCode
            )[0];
            if (!meter) return "";
            return `${meter.meterLocationStreet} ${meter.meterLocationStreetNumber}, ${meter.meterLocationPoCode} ${meter.meterLocationCity}`;
        };
    },
    /**
     * @function get full customer name
     * @param {Object} state => {String} customerId
     * @returns {function(*): T[]} => {Object}
     */
    getCustomerString(state) {
        return (customerId) => {
            const c = state.customers.filter(
                (customer) => customer.customerId === customerId
            )[0];
            return `${c.salutation ? c.salutation : ""} ${
                c.name ? c.name : ""
            } ${c.lastName ? c.lastName : ""} ${
                c.nameAddon ? c.nameAddon : ""
            }`;
        };
    },
    /**
     * @function get reading reason (Zählpunkt)
     * @param {Object} state => {String} meteringCode
     * @returns {function(*): T[]} => {String}
     */
    getIndividualReadingReason(state) {
        return (meteringCode) =>
            state.readings.filter(
                (meter) => meter.meteringCode === meteringCode
            )[0]?.readingReason;
    },
    /**
     * @function get reading date (Zählpunkt)
     * @param {Object} state => {String} meteringCode
     * @returns {function(*): T[]} => {String}
     */
    getIndividualReadingDate(state) {
        return (meteringCode) => {
            return state.readings.filter(
                (meter) => meter.meteringCode === meteringCode
            )[0]?.readingDate;
        };
    },
    /**
     * @function get reading value of meter
     * @param {Object} state => {String} meterRegisterId
     * @returns {function(*): T[]} => {String}
     */
    getReadingValue(state) {
        return (meterRegisterId) => {
            return state.readings.find(
                (meter) => meter.meterRegisterId === meterRegisterId
            ).meterReadingValue;
        };
    },
    /**
     * @function get implausible reason of meterRegister
     * @param {Object} state => {String} meterRegisterId
     * @returns {function(*): T[]} => {String}
     */
    getImplausibleReason(state) {
        return (meterRegisterId) => {
            return state.readings.find(
                (meter) => meter.meterRegisterId === meterRegisterId
            ).implausibleReason;
        };
    },
    /**
     * @function get implausibleReasonOther of meterRegister
     * @param {Object} state => {String} meterRegisterId
     * @returns {function(*): T[]} => {String}
     */
    getImplausibleReasonOther(state) {
        return (meterRegisterId) => {
            return state.readings.find(
                (meter) => meter.meterRegisterId === meterRegisterId
            ).implausibleReasonOther;
        };
    },
    /**
     * @function get image properties of meterRegister
     * @param {Object} state => {String} meterRegisterId
     * @returns {function(*): T[]} => {Object}
     */
    getImageProps(state) {
        return (meterRegisterId) => {
            return state.readings.find(
                (meter) => meter.meterRegisterId === meterRegisterId
            ).image;
        };
    },
};
