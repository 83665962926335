<script setup>
/******************************************************************************
 * PageComponent: Confirm > Meters
 *****************************************************************************/
import PageSection from "Components/PageSection/PageSection.vue";
import ConfirmMeterRegister from "./ConfirmMeterRegister.vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const props = defineProps({
    readings: {
        type: Array,
        required: true,
    },
});
const meteringCodes = props.readings
    .map((reading) => reading.meteringCode)
    .filter((value, index, self) => self.indexOf(value) === index);
const getMeterRegisterIds = (meteringCode) =>
    data
        .getReadingRegisters(meteringCode)
        .map((meterRegister) => meterRegister.meterRegisterId);
const getMediaType = (meteringCode) =>
    data.getMediaTypeData(data.getMeterData(meteringCode)?.meterMediaType);
const getMeterNumber = (meteringCode) =>
    data.getMeterData(meteringCode)?.meterNumber;
</script>

<template>
    <page-section hdl="Ihre Ablesungen">
        <div v-if="data.meters.length > readings.length" class="hint">
            Hinweis: Sie haben nicht alle Zähler abgelesen. Falls gewünscht
            können Sie noch weitere Zähler ablesen.
        </div>
        <ul class="meters">
            <li
                v-for="meteringCode in meteringCodes"
                :key="meteringCode"
                class="meter"
            >
                <header>
                    <h1>
                        {{ getMediaType(meteringCode).label }}-Zähler
                        {{ getMeterNumber(meteringCode) }}
                    </h1>
                    <aside class="meter-point">
                        <svg-icon name="location" :size="0" />
                        Abnahmestelle: {{ data.getMeterAdress(meteringCode) }}
                    </aside>
                    <span
                        v-if="
                            data.getCustomersForMeter(meteringCode).length === 1
                        "
                        class="customer"
                    >
                        <svg-icon name="customer" :size="0" />
                        <span class="text">
                            Vertragsnehmer:
                            {{
                                data.getCustomerString(
                                    data.getCustomersForMeter(meteringCode)[0]
                                        .customerId
                                )
                            }}
                            Kundennummer:
                            <cite>{{
                                data.getCustomersForMeter(meteringCode)[0]
                                    .customerNo
                            }}</cite>
                        </span>
                    </span>
                </header>
                <ul class="registers">
                    <confirm-meter-register
                        v-for="meterRegisterId in getMeterRegisterIds(
                            meteringCode
                        )"
                        :key="meterRegisterId"
                        :meter-register-id="meterRegisterId"
                    />
                </ul>
            </li>
        </ul>
    </page-section>
</template>

<style lang="scss" scoped>
.hint {
    padding: #{get($sizes, "base") * 0.6};
    margin: 0 0 #{get($sizes, "base") * 0.6} 0;

    background-color: get($client, "formReqBg");

    font-weight: 400;

    @include respond-to("medium") {
        margin: 0 0 #{get($sizes, "base") * 1.2} 0;
    }
}

.meters {
    display: flex;
    flex-direction: column;

    padding: 0;
    margin: 0;
    gap: #{get($sizes, "base") * 1};

    list-style: none;

    @include respond-to("medium") {
        gap: #{get($sizes, "base") * 1.5};
    }
}

.meter {
    padding: get($sizes, "border");
    border: get($sizes, "border") solid get($client, "meterBorderColor");

    border-radius: get($sizes, "radius");

    header {
        display: flex;
        flex-flow: column wrap;

        padding: #{get($sizes, "base") * 0.25};
        gap: #{get($sizes, "base") * 0.2};

        background-color: get($client, "meterHeaderBg");
        color: get($client, "meterHeaderColor");
        border-radius: #{get($sizes, "radius")} #{get($sizes, "radius")} 0 0;

        @include respond-to("medium") {
            justify-content: space-between;
            flex-direction: row;

            padding: #{get($sizes, "base") * 0.5};
        }
    }

    h1 {
        margin: 0;

        font-size: #{get($sizes, "base") + 2px};
        font-weight: 400;
    }
}

.registers {
    padding: 0;
    margin: #{get($sizes, "base") * 1} #{get($sizes, "base") * 0.25};

    list-style: none;

    @include respond-to("medium") {
        margin: #{get($sizes, "base") * 1} #{get($sizes, "base") * 0.5};
    }
}

.meter-point,
.customer {
    display: flex;
    align-items: center;

    gap: #{get($sizes, "base") * 0.2};
}

.customer {
    flex: 0 0 100%;

    cite {
        color: palette("brand1", "color");

        font-style: normal;
    }
}
</style>
