<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk) Informationen
 *****************************************************************************/
import FormRow from "Components/Form/FormRow.vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
});
const meterRegisterData = data.getMeterRegisterData(props.meterRegisterId);
const meterHasOtherRegisters =
    data.meters.filter(
        (meter) => meter.meteringCode === meterRegisterData.meteringCode
    ).length > 1;
const customerData = data.getCustomerData(meterRegisterData.customerId);
</script>

<template>
    <form-row
        v-if="
            data.getCustomersForMeter(meterRegisterData.meteringCode).length ===
            1
        "
        :bottom="0.2"
        :required="false"
        label=""
        for-id="meterRegisterInfo"
    >
        <template #input>
            <div class="meter-register-information">
                <aside v-if="meterHasOtherRegisters" class="meta highlighted">
                    Zählwerk {{ meterRegisterData.meterRegisterNumber }}
                </aside>
                <aside class="meta">{{ meterRegisterData.description }}</aside>
                <aside class="meta">
                    <span
                        title="Anhand der OBIS-Kennzahl können Sie das richtige Zählwerk identifizieren"
                        aria-label="Anhand der OBIS-Kennzahl können Sie das richtige Zählwerk identifizieren"
                        >OBIS-Kennzahl:</span
                    >
                    {{ meterRegisterData.obis }}
                </aside>
            </div>
        </template>
    </form-row>
    <div
        v-if="
            data.getCustomersForMeter(meterRegisterData.meteringCode).length > 1
        "
        class="meter-register-information meter-register-information--dual"
    >
        <aside class="meta customer">
            <svg-icon name="customer" :size="0" />
            <span class="text">
                <strong>Vertragsnehmer:</strong>
                {{ data.getCustomerString(customerData.customerId) }}
                <cite
                    title="Ihre Kundennummer"
                    aria-label="Ihre Kundennummer"
                    >{{ customerData.customerNo }}</cite
                >
            </span>
        </aside>
        <aside class="meta highlighted">
            Zählwerk {{ meterRegisterData.meterRegisterNumber }}
        </aside>
        <aside class="meta">{{ meterRegisterData.description }}</aside>
        <aside class="meta">
            <span
                title="Anhand der OBIS-Kennzahl können Sie das richtige Zählwerk identifizieren"
                aria-label="Anhand der OBIS-Kennzahl können Sie das richtige Zählwerk identifizieren"
                >OBIS-Kennzahl:</span
            >
            {{ meterRegisterData.obis }}
        </aside>
    </div>
</template>

<style lang="scss" scoped>
.meter-register-information {
    display: flex;
    flex-wrap: wrap;

    gap: #{get($sizes, "base") * 0.5};

    @include respond-to("medium") {
        gap: #{get($sizes, "base") * 0.75};
    }

    &--dual {
        margin-bottom: #{get($sizes, "base") * 0.2};

        @include respond-to("medium") {
            justify-content: center;

            margin-bottom: #{get($sizes, "base") * 0.4};
        }
    }
}

.meta {
    padding: #{get($sizes, "base") * 0.25} #{get($sizes, "base") * 0.5};
    border: get($sizes, "border") solid get($client, "meterMetaBorderColor");

    background-color: get($client, "meterMetaBg");
    color: get($client, "meterMetaColor");
    border-radius: get($sizes, "radius");

    line-height: 1;

    &.highlighted {
        background-color: get($client, "meterMetaHighlightBg");
        color: get($client, "meterMetaHighlightColor");
        border-color: get($client, "meterMetaHighlightBorderColor");
    }

    &.customer {
        display: flex;
        align-items: center;

        gap: #{get($sizes, "base") * 0.2};

        cite {
            color: palette("brand1", "color");

            font-style: normal;
        }
    }
}
</style>
