<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk) image upload
 *****************************************************************************/
import { ref } from "vue";
import FormRow from "Components/Form/FormRow.vue";
import SvgIcon from "Components/SvgIcon/SvgIcon.vue";
import RequestProgress from "Components/Loading/RequestProgress.vue";
import FormError from "Components/Form/FormError.vue";
import LoadingSpinner from "Components/Loading/LoadingSpinner.vue";
import MeterImagePreview from "./MeterImagePreview.vue";
import MeterImageRequirements from "./MeterImageRequirements.vue";
import { useReadingStore } from "Stores/reading";
const data = useReadingStore();
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
});
const meterRegisterData = data.getMeterRegisterData(props.meterRegisterId);
const meteringCode = meterRegisterData.meteringCode;
const meterHasMultipleRegisters =
    data.meters.filter((meter) => meter.meteringCode === meteringCode).length >
    1;
const fileUpload = ref(null);
const imageProps = data.getImageProps(props.meterRegisterId);
const isUploading = () =>
    imageProps.progress !== 0 && imageProps.progress !== 100;
const isDownloading = () => imageProps.progress === 100 && !imageProps.thumb;
const onChange = () => {
    const fileObj = fileUpload.value.files[0];
    if (fileObj.name)
        data.uploadImage(
            meterRegisterData.customerId,
            props.meterRegisterId,
            fileObj
        );
};
</script>

<template>
    <form-row
        :top="0.5"
        :required="false"
        :label="
            meterHasMultipleRegisters
                ? `Bild von Zählwerk ${meterRegisterData.meterRegisterNumber}`
                : 'Bild des Zählerstandes'
        "
        :for-id="`readingImage-${meterRegisterId}`"
    >
        <template #input>
            <label
                v-if="!isUploading()"
                class="form-input form-input--has-addon file-upload"
                :for="`readingImage-${meterRegisterId}`"
            >
                <input
                    :id="`readingImage-${meterRegisterId}`"
                    ref="fileUpload"
                    type="file"
                    accept="image/*"
                    @change="onChange"
                />
                <span
                    v-if="imageProps.originalFileName && !isDownloading()"
                    class="image-name"
                >
                    <span>{{ imageProps.originalFileName }}</span>
                    <strong>✔</strong>
                </span>
                <span v-if="!imageProps.originalFileName && !isDownloading()"
                    >Bild auswählen</span
                >
                <span v-if="isDownloading()">Bitte warten...</span>
            </label>
            <label
                v-if="!isUploading()"
                class="form-input-addon"
                :for="`readingImage-${meterRegisterId}`"
            >
                <svg-icon
                    v-if="
                        imageProps.progress !== 100 &&
                        !imageProps.originalFileName
                    "
                    name="add_photo"
                />
                <svg-icon
                    v-if="
                        imageProps.progress !== 100 &&
                        imageProps.originalFileName
                    "
                    name="photo"
                />
                <loading-spinner v-if="isDownloading()" />
            </label>
            <request-progress
                v-if="isUploading()"
                :percentage="imageProps.progress"
            />
        </template>
        <template #text>
            <meter-image-requirements v-if="imageProps.thumb.length === 0" />
            <form-error
                v-if="imageProps.errors.length > 0"
                :top="0.2"
                :error-array="imageProps.errors"
            />
            <meter-image-preview
                v-if="imageProps.thumb.length > 0"
                :meter-register-id="meterRegisterId"
                :thumb="imageProps.thumb"
            />
        </template>
    </form-row>
</template>

<style lang="scss" scoped>
$addon-width: 46px;
$padding-width: 24px;

.file-upload {
    max-width: calc(100% - #{$addon-width});
    height: 42px;

    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.form-input-addon {
    cursor: pointer;
}

.image-name {
    display: flex;
    align-items: center;

    overflow: hidden;
    max-width: calc(100% - #{$addon-width} - #{$padding-width});

    white-space: nowrap;
    text-overflow: ellipsis;

    > span {
        overflow: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>
