<script setup>
/******************************************************************************
 * PageComponent: MeterReading
 *****************************************************************************/
import FormRequiredHint from "Components/Form/FormRequiredHint.vue";
import FormNavRow from "Components/Form/FormNavRow.vue";
import PageSection from "Components/PageSection/PageSection.vue";
import FormButton from "Components/Form/FormButton.vue";
import SingleCustomerData from "./SingleCustomerData.vue";
import MetaDataDateAndReasonType from "./DateAndReasonType.vue";
import ReadingReason from "./DateAndReasonType/ReadingReason.vue";
import ReadingDate from "./DateAndReasonType/ReadingDate.vue";
import MeterList from "./Meters/MeterList.vue";
import PageMeterReadingErrors from "./PageMeterReadingErrors.vue";
import { isDevelopment } from "@/handlers/useEnvironment";
import { getReadingErrors } from "@/handlers/useMeterReadingValidation";
import { useReadingStore } from "Stores/reading";
import { useNavigationStore } from "Stores/navigation";
import { metersContainDismountedRegister } from "@/handlers/useReading";
import { useRouter } from "vue-router";
import { URLS } from "@/config";
const data = useReadingStore();
const nav = useNavigationStore();
const router = useRouter();
const onDateChanged = (value) => {
    isDevelopment && console.log(`changed global reading date to "${value}".`);
    data.globalReadingDate = value;
};
const onReasonChanged = (value) => {
    isDevelopment &&
        console.log(`changed global reading reason to "${value}".`);
    data.globalReadingReason = value;
};
const onForwardClick = () => {
    if (getReadingErrors().length === 0) {
        nav.changeStatus("MeterReading", "done");
        nav.changeStatus("Confirm", "");
        router.push({ name: "Confirm" });
    }
};
const onBackwardClick = () => router.push({ name: "Auth" });
</script>

<template>
    <main class="page">
        <page-section hdl="Zählerstände">
            <p v-if="data.config.allMetersRequired">
                Bitte geben Sie alle Zählerstände an.
            </p>
            <p v-else>
                Bitte geben Sie die Zählerstände mindestens eines Zählers an.
                Wir verarbeiten Ihre Daten nach den gesetzlichen Bestimmungen.
                <a :href="URLS.privacy" target="_blank">Datenschutz</a>
            </p>
        </page-section>
        <single-customer-data v-if="data.customers.length === 1" />
        <page-section
            v-if="!metersContainDismountedRegister()"
            hdl="Ablesedaten"
        >
            <meta-data-date-and-reason-type />
            <reading-reason
                v-if="data.dateAndReasonType === 'global'"
                :selected-reason="data.globalReadingReason"
                for-id="globalReadingReason"
                @change="onReasonChanged"
            />
            <reading-date
                v-if="data.dateAndReasonType === 'global'"
                :initial-date="data.globalReadingDate"
                for-id="globalReadingDate"
                @change="onDateChanged"
            />
        </page-section>
        <page-section hdl="Ihre Zähler">
            <meter-list />
        </page-section>
        <form-required-hint
            v-if="data.config.allMetersRequired"
            :top="1"
            additional-text="Es müssen alle Zähler abgelesen werden."
        />
        <form-required-hint
            v-else
            :top="1"
            additional-text="Es muss mindestens ein Zähler vollständig abgelesen werden."
        />
        <page-meter-reading-errors
            v-if="getReadingErrors().length > 0"
            :errors="getReadingErrors()"
        />
        <form-nav-row :top="1">
            <template #back>
                <form-button
                    text="Zurück"
                    icon-position="left"
                    icon-name="back"
                    :disabled="data.requesting"
                    importance="default"
                    @click="onBackwardClick"
                />
            </template>
            <template #forward>
                <form-button
                    text="Weiter"
                    icon-position="right"
                    icon-name="forward"
                    :disabled="getReadingErrors().length > 0 || data.requesting"
                    importance="primary"
                    @click="onForwardClick"
                />
            </template>
        </form-nav-row>
    </main>
</template>
